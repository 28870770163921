import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./auth_config.json"
import AuthorizedApolloProvider from "./common/utils/AuthorizedApolloProvider"
import { UserContextProvider } from './common/context/UserContext'
import { GlobalDataContextProvider } from './common/context/GlobalDataContext'

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      audience={config.audience}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <AuthorizedApolloProvider>
        <UserContextProvider>
          <GlobalDataContextProvider>
            <App />
          </GlobalDataContextProvider>
        </UserContextProvider>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
