import React, { useState, useRef, useEffect } from 'react'
import DataSelector from '../views/DataSelector'
import TimeLineChart from '../views/TimeLineChart';
import RespirationChart from '../views/RespirationChart';
import { useParams } from 'react-router-dom';
import { ChartDataArr, DataUtilsPimp } from '../common/utils/DataUtilsPimp'

export default function DataPage() {

  const { cowId } = useParams<{ cowId: string }>();

  const [timeLine, setTimeLine] = useState([]);
  const [respirationData, setRespirationData] = useState<ChartDataArr | null>(null);
  const [respirationRawData, setRespirationRawData] = useState([]);
  const id = useRef('');

  const { parseToArrOfArrs } = DataUtilsPimp();

  useEffect(() => {
    if (!respirationRawData) return;
    if (respirationRawData.length > 1) {
      parseToArrOfArrs(respirationRawData)
        .then((res: any) => {
          setRespirationData(res);
        })
    }
  }, [respirationRawData])

  return (
    <div className="col-centered">
      {/* <DataViewProvider> */}
      <DataSelector
        onTimeLine={(data: any) => setTimeLine(data)}
        onChartData={(data: any) => setRespirationRawData(data)}
        // onChartData={(data: any) => setRespirationData(data)}
        onIDChange={(data: string) => id.current = data}
        cowIdProp={cowId}
      />
      <RespirationChart data={respirationData} title={id.current} />
      <TimeLineChart data={timeLine} title={id.current} dataId={id.current} onData={(data: any) => setRespirationData(data)} />
    </div>
  )
}
