import React from 'react'
import SensorsTable from '../views/SensorsTable'
const SensorsView = (props: any) => {
  return (
    <div>
      <SensorsTable />
    </div>
  )
}

export default SensorsView;
