import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button"
import { gql, useMutation } from "@apollo/client";
import { useMsgBox } from "../common/context/GlobalMessageBoxContext";
// import { SensorModel } from '../common/models/SensorsModel';

type Inputs = {
  sensorId: number,
  cowId: string,
  access: string
  tags: string,
  groups: string
};

export default function FormAddSensor() {

  const { setMsgBox } = useMsgBox();

  const { register, handleSubmit, errors: formErrors, } = useForm<Inputs>({
    mode: "all",
    // defaultValues: {
    //   access: "admin"
    // }
  });


  const [addSensor, { loading, error: gqlError, data }] = useMutation(gql`
    mutation addSensor($cowId: String, $sensorId: Int, $groups: [String!], $tags: [String!], $access: [String!]) {
      addSensor(
          cowId: $cowId,
          sensorId: $sensorId,
          groups: $groups,
          tags: $tags,
          access: $access
        ){
          cow_id,
          sensor_id,
          groups,
          tags
        },
      },
      `,
    {
      errorPolicy: 'all'
    }
  );

  useEffect(() => {
    setMsgBox({ show: loading, message: "Loading...", autoHide: false })
  }, [loading])

  useEffect(() => {
    if (data) setMsgBox({ show: true, message: JSON.stringify(data.addSensor), autoHide: true })
  }, [data])

  useEffect(() => {
    if (gqlError) setMsgBox({ show: true, message: gqlError.message, severity: 'warning', autoHide: false });
  }, [gqlError]);

  const onSubmit = (data: any) => {
    if (data.cowId.length < 1 && data.sensorId < 1)
      alert("Please enter at least a cow or sensor id")
    let accessArr = data.access.split(",");
    let groupsArr = data.groups.split(",");
    let tagsArr = data.tags.split(",");

    addSensor(
      {
        variables: {
          sensorId: parseInt(data.sensorId),
          cowId: data.cowId,
          access: accessArr,
          groups: groupsArr,
          tags: tagsArr,
        }
      }
    )

  };


  return (
    <div style={{ display: "inline-block" }}>

      <Paper style={{ padding: "12px", marginBottom: "12px" }}>
        <h3>Add new sensor</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup style={{ display: "flex", flexDirection: "row" }}>

            <FormControl >
              <InputLabel shrink={true} htmlFor="sensorId">Sensor Id</InputLabel>
              <Input name="sensorId" inputRef={register({
                validate: (input) => !isNaN(input)
              })} />
              <FormHelperText id="my-helper-text" style={formErrors.sensorId ? { color: "red" } : {}}>Must be a number</FormHelperText>
            </FormControl>

            <FormControl >
              <InputLabel shrink={true} htmlFor="cowId">Cow Id</InputLabel>
              <Input name="cowId" inputRef={register} />
              <FormHelperText id="my-helper-text">Cow name or number</FormHelperText>
            </FormControl>

            <FormControl error={formErrors.groups ? true : false}>
              <InputLabel shrink={true} htmlFor="access" >Groups</InputLabel>
              <Input name="groups" inputRef={register()} />
              <FormHelperText id="my-helper-text-required">Comma separated names</FormHelperText>
            </FormControl>

            <FormControl error={formErrors.tags ? true : false}>
              <InputLabel shrink={true} htmlFor="tags" >Tags</InputLabel>
              <Input name="tags" inputRef={register()} />
              <FormHelperText id="my-helper-text-required">Comma separated names</FormHelperText>
            </FormControl>

            <FormControl error={formErrors.access ? true : false}>
              <InputLabel shrink={true} htmlFor="access" >Access Groups</InputLabel>
              <Input name="access" inputRef={register({ required: false, })} />
              <FormHelperText id="my-helper-text-required">Comma separated names</FormHelperText>
            </FormControl>

            <Button type="submit" title="Submit">Submit</Button>

          </FormGroup>
        </form>
      </Paper>

    </div>
  );
}