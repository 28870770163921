/* eslint-disable */
export interface SensorRawData {
  iCowId: number;
  iTimeOffset: number;
  iTimestamp: string;
  iValuesCount: number;
  iValuesInterval: number;
  sCowName: string;
  sTimestamp: string;
  sValuesAccelerationX: string;
  sValuesAccelerationY: string;
  sValuesAccelerationZ: string;
  sValuesPressure: string;
  sqlId: number;
  respirationFrequency: number;
}

export interface ChartDataArr {
  startTime: number;
  endTime: number;
  pointInterval: number;
  pressure: Array<Array<number>>;
  accX: Array<Array<number>>;
  accY: Array<Array<number>>;
  accZ: Array<Array<number>>;
  // freq: Array<Array<number>>;
  // freq2: Array<Array<number>>;
  // simplifyjs: Array<Array<number>>;
  respirationFrequency: Array<Array<number>>;
  // freqDataPoints: Array<Array<number>>;
}

// Simplify.js 

// to suit your point format, run search/replace for '.x' and '.y';
// for 3D version, see 3d branch (configurability would draw significant performance overhead)

// square distance between 2 points
function getSqDist(p1: any, p2: any) {

  var dx = p1.x - p2.x,
    dy = p1.y - p2.y;

  return dx * dx + dy * dy;
}

// square distance from a point to a segment
function getSqSegDist(p: any, p1: any, p2: any) {

  var x = p1.x,
    y = p1.y,
    dx = p2.x - x,
    dy = p2.y - y;

  if (dx !== 0 || dy !== 0) {

    var t = ((p.x - x) * dx + (p.y - y) * dy) / (dx * dx + dy * dy);

    if (t > 1) {
      x = p2.x;
      y = p2.y;

    } else if (t > 0) {
      x += dx * t;
      y += dy * t;
    }
  }

  dx = p.x - x;
  dy = p.y - y;

  return dx * dx + dy * dy;
}
// rest of the code doesn't care about point format

// basic distance-based simplification
function simplifyRadialDist(points: any, sqTolerance: any) {

  var prevPoint = points[0],
    newPoints = [prevPoint],
    point;

  for (var i = 1, len = points.length; i < len; i++) {
    point = points[i];

    if (getSqDist(point, prevPoint) > sqTolerance) {
      newPoints.push(point);
      prevPoint = point;
    }
  }

  if (prevPoint !== point) newPoints.push(point);

  return newPoints;
}

function simplifyDPStep(points: any, first: any, last: any, sqTolerance: any, simplified: any) {
  var maxSqDist = sqTolerance,
    index;

  for (var i = first + 1; i < last; i++) {
    var sqDist = getSqSegDist(points[i], points[first], points[last]);

    if (sqDist > maxSqDist) {
      index = i;
      maxSqDist = sqDist;
    }
  }

  if (maxSqDist > sqTolerance) {
    if (index - first > 1) simplifyDPStep(points, first, index, sqTolerance, simplified);
    simplified.push(points[index]);
    if (last - index > 1) simplifyDPStep(points, index, last, sqTolerance, simplified);
  }
}

// simplification using Ramer-Douglas-Peucker algorithm
function simplifyDouglasPeucker(points: any, sqTolerance: any) {
  var last = points.length - 1;

  var simplified = [points[0]];
  simplifyDPStep(points, 0, last, sqTolerance, simplified);
  simplified.push(points[last]);

  return simplified;
}

// both algorithms combined for awesome performance
function simplify(points: any, tolerance: any, highestQuality: any) {

  if (points.length <= 2) return points;

  var sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

  points = highestQuality ? points : simplifyRadialDist(points, sqTolerance);
  points = simplifyDouglasPeucker(points, sqTolerance);

  return points;
}

// Simplify.js

function mean(numbers: any) {
  let total = 0, i;
  for (i = 0; i < numbers.length; i += 1) {
    total += numbers[i].Pressure;
  }
  return total / numbers.length;
}

function max(array: any, start: any, count: any) {
  let res = array[start];
  for (let index = 0; index < count; index++) {
    const element = array[start + index];

    if (element.Pressure > res.Pressure) {
      res = element;
    }
  }
  res.local = "MAX";
  return res.Pressure;
}
function min(array: any, start: any, count: any) {
  let res = array[start];
  for (let index = 0; index < count; index++) {
    const element = array[start + index];

    if (element.Pressure < res.Pressure) {
      res = element;
    }
  }
  res.local = "MIN";
  return res.Pressure;
}

function max_min(thresholdVar: number, mean: number, array: any, start: any, count: any) {
  let max = array[start];
  let min = array[start];
  for (let index = 0; index < count; index++) {
    const element = array[start + index];

    // if (element.Pressure > mean + 500 && element.Pressure > max.Pressure) {
    if (element.Pressure > (mean + thresholdVar)) {
      // if (element.Pressure > max.Pressure) {
      max = element;
      element.local = "MAX";
    }
    // if (element.Pressure < mean - 500 && element.Pressure < min.Pressure) {
    if (element.Pressure < (mean - thresholdVar)) {
      // if (element.Pressure < min.Pressure) {
      min = element;
      element.local = "MIN";
    }
  }
  // max.local = "MAX";
  // min.local = "MIN";
}

function datenAuswertung(window: number, thresholdVar: number, mean: number, jsonObj: any, localMinMaxArray: Array<Array<number>>, freq2: Array<Array<number>>) {

  for (let index = 0; index < jsonObj.length - window - 1; index++) {
    max_min(thresholdVar, mean, jsonObj, index, window);
  }

  let count = 0;
  for (let index = 0; index < jsonObj.length; index++) {
    const element = jsonObj[index];
    if (element['local'] && element.local === "MAX") {
      element.local = undefined;
      count++;
    }
    else {
      if (count != 0) {
        max(jsonObj, index - count, count);
      }
      count = 0;
    }
  }

  count = 0;
  for (let index = 0; index < jsonObj.length; index++) {
    const element = jsonObj[index];
    if (element['local'] && element.local === "MIN") {
      element.local = undefined;
      count++;
    }
    else {
      if (count != 0) {
        min(jsonObj, index - count, count);
      }
      count = 0;
    }
  }
  let lastTimestamp = 0;
  let toggle = 0;
  for (let index = 0; index < jsonObj.length; index++) {
    const element = jsonObj[index];
    if (element['local'] && element.local === "MAX" && toggle == 0) {
      localMinMaxArray.push([element.DateTime, element.Pressure]);
      if (lastTimestamp != 0)
        freq2.push([element.DateTime, Math.round((1 / ((element.DateTime - lastTimestamp) / 1000)) * 60)]);
      lastTimestamp = element.DateTime;
      count++;
      toggle = 1;
    }

    if (element['local'] && element.local === "MIN" && toggle == 1) {
      localMinMaxArray.push([element.DateTime, element.Pressure]);
      count++;
      toggle = 0;
    }
  }
  return (count / 2) * 2; // 30s * 2 = 60s
}

export function DataUtilsPimp() {
  const parseToArrOfArrs = (obj: Array<SensorRawData>): Promise<ChartDataArr> => {
    return new Promise((resolve, reject) => {
      if (obj !== undefined && obj.length > 0) {
        let interval = 0;
        let dataCollection1: Array<Array<number>> = [];
        let dataCollection2: Array<Array<number>> = [];
        let dataCollection3: Array<Array<number>> = [];
        let dataCollection4: Array<Array<number>> = [];
        // let dataCollection5: Array<Array<number>> = [];
        // let dataCollection6: Array<Array<number>> = [];
        // let dataCollection7: Array<Array<number>> = [];
        // let dataCollection8: Array<Array<number>> = [];
        let dataCollection9: Array<Array<number>> = [];

        const startTime = Number(obj[0].iTimestamp);
        const temp: SensorRawData = obj[obj.length - 1];
        const endTime: number = Number(temp.iTimestamp) + (temp.iValuesCount + temp.iValuesInterval);
        const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

        // await Promise.all(
        obj.forEach((e: SensorRawData) => {
          interval = e.iValuesInterval;
          let timeStart = Number(parseInt(e.iTimestamp) - tzOffset);
          let startTime1 = timeStart;
          let startTime2 = timeStart;
          let startTime3 = timeStart;
          let startTime4 = timeStart;

          const pres = JSON.parse(e.sValuesPressure);
          const accX = JSON.parse(e.sValuesAccelerationX);
          const accY = JSON.parse(e.sValuesAccelerationY);
          const accZ = JSON.parse(e.sValuesAccelerationZ);

          dataCollection9.push([e.iValuesCount * e.iValuesInterval + timeStart, e.respirationFrequency])
          // dataCollection9.push([timeStart, e.respirationFrequency]);
          // console.log(new Date(timeStart).toLocaleTimeString());
          //let i = 0;
          pres.map((val: string) => {
            //if (i++ % 5)
            dataCollection1.push([startTime1, parseFloat(val)]);
            startTime1 += interval;
          });
          accX.map((val: string) => {
            //if (i++ % 5)
            dataCollection2.push([startTime2, parseFloat(val)]);
            startTime2 += interval;
          });
          accY.map((val: string) => {
            //if (i++ % 5)
            dataCollection3.push([startTime3, parseFloat(val)]);
            startTime3 += interval;
          });
          accZ.map((val: string) => {
            //if (i++ % 5)
            dataCollection4.push([startTime4, parseFloat(val)]);
            startTime4 += interval;
          });
        });

        dataCollection1 = dataCollection1.sort((p1, p2) => p1[0] - p2[0]);
        dataCollection2 = dataCollection2.sort((p1, p2) => p1[0] - p2[0]);
        dataCollection3 = dataCollection3.sort((p1, p2) => p1[0] - p2[0]);
        dataCollection4 = dataCollection4.sort((p1, p2) => p1[0] - p2[0]);
        dataCollection9 = dataCollection9.sort((p1, p2) => p1[0] - p2[0]);

        // let tolerance = 100;
        // dataCollection6 = simplify(dataCollection1.map(a => { return { x: a[0], y: a[1] }; }), tolerance, false).map((a: any) => { return [a.x, a.y]; });

        // let jsonObj2 = dataCollection1.map(a => { return { DateTime: a[0], Pressure: a[1] } });
        // let lastTime = jsonObj2[0].DateTime;
        // let array: Array<any> = [];
        // jsonObj2.forEach(x => {
        //   if (lastTime % 100 == x.DateTime % 100) {
        //     lastTime = x.DateTime;
        //     array.push(x);
        //   }
        //   else {
        //     if (array.length > 0) {
        //       // array = simplify(array.map(a => { return { x: a.DateTime, y: a.Pressure }; }), tolerance, false)
        //       //   .map((a: any) => { return { DateTime: a.x, Pressure: a.y }; });

        //       let meanVar = mean(array);
        //       let maxVar = max(array, 0, array.length);
        //       let minVar = min(array, 0, array.length);
        //       //@ts-ignore
        //       let thresholdVar = (maxVar - meanVar) * 0.2;

        //       let freq = datenAuswertung(15, thresholdVar, meanVar, array, dataCollection7, dataCollection8);
        //       dataCollection5.push([lastTime, freq]);
        //     }
        //     array = [];
        //     lastTime = x.DateTime;
        //   }
        // });
        // df

        resolve({
          pointInterval: interval,
          startTime: startTime,
          endTime: endTime,
          pressure: dataCollection1,
          accX: dataCollection2,
          accY: dataCollection3,
          accZ: dataCollection4,
          // freq: dataCollection5,
          // simplifyjs: dataCollection6,
          // freqDataPoints: dataCollection7,
          // freq2: dataCollection8,
          respirationFrequency: dataCollection9
        });
      } else {
        reject('no data');
      }
    });
  }

  return {
    parseToArrOfArrs
  }
}