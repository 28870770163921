import React, { useContext } from 'react'
import { UserContext, IUserContext } from '../common/context/UserContext';


const IssueTrackerPage = (props: any) => {

  const { userCtx } = useContext(UserContext) as IUserContext;

  const styles = {
    item: {
      display: "flex",
      flexGrow: 1,
      margin: 8,
      padding: 4,
      backgroundColor: "lightGray",
      justifyContent: "center",
      alignItems: "start"
    }
  }

  return (
    <div>
      <div>
        HEADER
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={styles.item}>
          <p>Issues</p>
        </div>
        <div style={styles.item}>
          <p>
            Notes
          </p>
        </div>
        <div style={styles.item}>
          <p>
            Requests
          </p>
        </div>
      </div>
    </div >
  )
}

export default IssueTrackerPage;
