import { useAuth0 } from '@auth0/auth0-react';
import { Route, Redirect } from 'react-router';
import React from 'react';
import { RouteProps } from 'react-router-dom';

interface Props extends RouteProps {

}

const PrivateRoute: React.FC<Props> = ({ ...rest }) => {
    const { isAuthenticated } = useAuth0();
    if (!isAuthenticated) return <Redirect to="/" />
    return (<Route {...rest} />);
}

export default PrivateRoute;