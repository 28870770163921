/**
 * Material UI TimePicker to pick date and time
 * @prop {Function} timeChanged
 * @prop {number} offset - to initially displayed time
 * @prop {string} label
 */

import 'date-fns';
import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from '@material-ui/pickers';

interface CompProps {
  timeChanged: Function;
  label: string;
  offset: number; // time offset for default value
}
export default function TimePicker(props: CompProps) {
  const [selectedDate, setSelectedDate] = useState<number | null>(new Date().getTime());

  useEffect(() => {
    setSelectedDate(new Date().getTime() - props.offset)
    props.timeChanged(selectedDate);
  }, [props.offset])

  // fire props.timeChanged once on render trigger parent component state 
  useEffect(() => {
    props.timeChanged(new Date().getTime() - props.offset);
  }, [])

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date.getTime());
      props.timeChanged(date.getTime());
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        autoOk
        ampm={false}
        disableFuture
        value={selectedDate}
        onChange={handleDateChange}
        label={props.label}
        format="dd/MM/yyyy - HH:mm:ss"
        style={{width: 166}}
      />
    </MuiPickersUtilsProvider>
  );
}
