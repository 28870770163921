import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@material-ui/core/Button';
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <Button
    startIcon={<AccountBoxTwoToneIcon />}
    style={{ width: 150, color: "white"}}
    variant="text"
    // color="default"
    onClick={() => loginWithRedirect({redirectUri: `${process.env.REACT_APP_URL}welcome`})}
    >Log In</Button>;
};

export default LoginButton;