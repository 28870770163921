// import '../App.css'; // wenn alles stimmt später mal löschen
import React, { useEffect, useState } from 'react'; import Button from '@material-ui/core/Button';
import { useLazyQuery, gql, useQuery } from '@apollo/client';
import { useMsgBox } from '../common/context/GlobalMessageBoxContext';
import StationsStatus from '../views/StationsStatus';
import Typography from '@material-ui/core/Typography';
import SensorsTable from '../views/SensorsTable';

function WelcomePage() {
  const { setMsgBox } = useMsgBox();

  const [stations, setStations] = useState<null | [{}]>();

  const { loading: gqlLoading, data: gqlLinksData, error: gqlError } = useQuery(
    gql`query getStations {
      getStations {
        date
        stationId
        nick
      }
    }
    `,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  );

  // gql loading
  useEffect(() => {
    if (gqlLoading) setMsgBox({ show: true, message: 'Loading', severity: 'info', autoHide: false });
    else setMsgBox({ show: false });
  }, [gqlLoading]);

  // gql error
  useEffect(() => {
    if (gqlError) setMsgBox({ show: true, message: `Error`, severity: 'warning', autoHide: true });
    else setMsgBox({ show: false });
  }, [gqlError]);

  // effect on data
  useEffect(() => {
    if (gqlLinksData) {
      setStations(gqlLinksData.getStations)
    }
  }, [gqlLinksData]);

  // useEffect(() => {
  //   getStations()
  // }, [])

  return (
    <div >
      <Typography variant="h4" gutterBottom>
        Stations:
      </Typography>
      <StationsStatus elements={stations} />
      <Typography variant="h4" gutterBottom>
        Sensors:
      </Typography>
      <SensorsTable />
    </div >
  );
}

export default WelcomePage;
