import React, { FC } from 'react'

interface Props {
  restrictedTo: string;
  userGroup: Array<string> | undefined
}

const RestrictedAccess: FC<Props> = ({ children, restrictedTo, userGroup }) => {

  if (!userGroup) return null;
  if (userGroup.includes(restrictedTo) || userGroup.includes('admin'))
    return (
      <>
        {children}
      </>
    )
  return null;
}

export default RestrictedAccess;