import React, { useState, useEffect, FunctionComponent } from 'react'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting'
import HC_dataExport from 'highcharts/modules/export-data'
import HC_boost from 'highcharts/modules/boost'
import HC_annotations from 'highcharts/modules/annotations'
import HC_noData from 'highcharts/modules/no-data-to-display'
import { ChartDataArr } from '../common/utils/DataUtilsPimp';
HC_exporting(Highcharts)
HC_dataExport(Highcharts)
HC_boost(Highcharts)
HC_annotations(Highcharts)
HC_noData(Highcharts)

interface Props {
  data: ChartDataArr | null; // Data for chart
  title: string;  // Title of Chart
}

export const RespirationChart: FunctionComponent<Props> = ({ data, title }) => {

  const chartConfig: Highcharts.Options = {
    title: {
      text: 'Respiration Data'
    },
    caption: {
      text: '* Use shift to scroll view',
      align: 'left',
    },
    exporting: {
      enabled: true,
      csv: {
        dateFormat: '%Y-%m-%d %H:%M:%S.%L'
      },
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS'
          ]
        }
      }
    },
    boost: {
      seriesThreshold: 100,
      enabled: true,
      allowForce: true
    },
    plotOptions: {
      line: {
        animation: false,
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          }
        }
      },
      series: {
        marker: {
          enabled: false
        },
        animation: false,
        allowPointSelect: false
      }
    },
    chart: {
      type: 'line',
      zoomType: 'x',
      panKey: 'shift',
      panning: {
        enabled: true,
        type: 'x'
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        //   // ###           Formate
        //   // ###  millisecond: '%H:%M:%S.%L',
        //   // ###       second: '%H:%M:%S',
        //   // ###       minute: '%H:%M',
        //   // ###         hour: '%H:%M',
        //   // ###          day: '%e. %b',
        //   // ###         week: '%e. %b',
        //   // ###        month: '%b \'%y',
        //   // ###         year: '%Y'
        formatter() {
          return Highcharts.dateFormat('%H:%M:%S', this.value)
          // return Highcharts.dateFormat('%H:%M:%S.%L', this.value);
        }
        // rotation: -10,
      }
    },
    yAxis: [
      {
        zoomEnabled: false,
        title: { text: 'Pressure' }
      },
      {
        zoomEnabled: false,
        title: { text: 'Movement' },
        opposite: true
      }
    ],
    lang: {
      noData: 'No Data'
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030'
      }
    },
    series: [],
    annotations: []
  }

  const [chartData, setChartData] = useState<Highcharts.Options>(chartConfig);

  /**
   * Triggered on update of data prop to update chart
   */
  useEffect(() => {
    // parseToArrOfArrs(data)
    // .then(chartData => {
    if (!data) return;
    if (data.hasOwnProperty("pressure"))
      setChartData(prevState => {
        return {
          ...prevState,
          title: {
            text: `Respiration Data ${title}`
          },
          series: [{
            boostThreshold: 1,
            yAxis: 0,
            name: 'Pressure',
            data: data.pressure.sort((p1: any, p2: any) => p1[0] - p2[0]),
            // data: chartData.pressure.sort((p1, p2) => p1[0] - p2[0]),
            type: 'line'
          },
          {
            boostThreshold: 1,
            yAxis: 1,
            name: 'Acc X',
            // data: data.accX.sort((p1: any, p2: any) => p1[0] - p2[0]),
            data: data.accX,
            type: 'line'
          },
          {
            boostThreshold: 1,
            yAxis: 1,
            name: 'Acc Y',
            // data: data.accY.sort((p1: any, p2: any) => p1[0] - p2[0]),
            data: data.accY,
            type: 'line'
          },
          {
            boostThreshold: 1,
            yAxis: 1,
            name: 'Acc Z',
            // data: data.accZ.sort((p1: any, p2: any) => p1[0] - p2[0]),
            data: data.accZ,
            type: 'line'
          },
          // {
          //   boostThreshold: 1,
          //   yAxis: 0,
          //   name: 'Simplified',
          //   data: data.simplifyjs.sort((p1: any, p2: any) => p1[0] - p2[0]),
          //   type: 'line'
          // },
          // {
          //   boostThreshold: 1,
          //   yAxis: 1,
          //   name: 'freq',
          //   data: data.freq.sort((p1: any, p2: any) => p1[0] - p2[0]),
          //   type: 'line'
          // },
          // {
          //   boostThreshold: 1,
          //   yAxis: 1,
          //   name: 'freq2',
          //   data: data.freq2.sort((p1: any, p2: any) => p1[0] - p2[0]),
          //   type: 'line'
          // },
          {
            boostThreshold: 1,
            yAxis: 1,
            name: 'respiration',
            data: data.respirationFrequency,
            type: 'line'
          },
          ]
        }
      })
    // })
    // .catch(err => console.log(err));
  }, [data])

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartData}
      />
    </div>
  )
}


export default RespirationChart;