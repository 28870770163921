import '../styles/TimeRangePicker.css';
import React, { useRef, useState, useEffect, useContext } from 'react';
import TimePicker from '../components/TimePicker';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { useMsgBox } from '../common/context/GlobalMessageBoxContext'
import { useAuth0 } from '@auth0/auth0-react';
import { gql, useLazyQuery } from '@apollo/client';
import RestrictedAccess from '../components/RestrictedAccess';
import { TextField } from '@material-ui/core';
import { IUserContext, UserContext } from '../common/context/UserContext';

interface Props {
  onChartData: Function;
  onTimeLine: Function;
  onIDChange: Function;
  cowIdProp?: string;
}

// Open Items
// TODO Live msgbox verschwindet
// TODO msgBox bei TimeLine Click

export default function DataSelector({ onChartData, onTimeLine, onIDChange, cowIdProp }: Props) {

  const { isAuthenticated, isLoading } = useAuth0();
  const { setMsgBox } = useMsgBox();

  const intervalId = useRef<any>();

  const [liveActive, setLiveActive] = useState(false);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [cowIds, setCowIds] = useState([]);
  const [cowId, setCowId] = useState('');
  const { userCtx, setUserCtx } = useContext(UserContext) as IUserContext;
  const manualSensorIdInputRef = useRef<HTMLInputElement>(null);

  const [getSensors, { loading: gqlLoading, data: gqlLinksData, error: gqlError }] = useLazyQuery(
    gql`query getSensors {
      getSensors{
        cow_id,
        groups,
        sensor_id,
        lastPing 
      },
    }`,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  );

  const [getTimeStamps, { loading: getTimeStampsLoading, data: gqlTimeStampsData, error: gqlTimeStampsError }] = useLazyQuery(
    gql`query timeLine {
      scanForData(id: "${cowId}")
    }`,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    }
  );

  const [getTimeFrame, { loading: gqlTimeFrameLoading, data: gqlTimeFrameData, error: gqlTimeFrameError }] = useLazyQuery(
    gql`query timeFrame($cowId: String!, $startTime: Float! $endTime: Float!) {
      getCowData(cowId: $cowId, startTime: $startTime, endTime: $endTime) {
        iTimestamp
        iValuesCount
        iValuesInterval
        sValuesPressure
        sValuesAccelerationX
        sValuesAccelerationY
        sValuesAccelerationZ
        respirationFrequency
      }
    }`,
    {
      variables: {
        cowId,
        startTime,
        endTime
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    }
  );

  // cleanup hook on unmount
  useEffect(() => {
    // return () => {
    //   stopLive();
    // }
  }, []);

  // auth0
  useEffect(() => {
    if (!isAuthenticated && isLoading) return;
    handleGetCows();
    if (cowIdProp) {
      setCowId(cowIdProp);
      onIDChange(cowIdProp);
      handleGetTimeLine();
    }
  }, [isAuthenticated, isLoading]);

  // gql loading
  useEffect(() => {
    if (gqlLoading || getTimeStampsLoading || gqlTimeFrameLoading) setMsgBox({ show: true, message: 'Loading', severity: 'info', autoHide: false });
    else setMsgBox({ show: false });
  }, [gqlLoading, getTimeStampsLoading, gqlTimeFrameLoading]);

  // gql error
  useEffect(() => {
    if (gqlError || gqlTimeStampsError || gqlTimeFrameError) setMsgBox({ show: true, message: `Error`, severity: 'warning', autoHide: true });
    else setMsgBox({ show: false });
  }, [gqlError, gqlTimeStampsError, gqlTimeFrameError]);

  // effect on data
  useEffect(() => {
    if (gqlLinksData) {
      setCowIds(gqlLinksData.getSensors)
    }
    if (gqlTimeStampsData) {
      onTimeLine(gqlTimeStampsData.scanForData);
    }
    if (gqlTimeFrameData) {
      onChartData(gqlTimeFrameData.getCowData)
    }
  }, [gqlLinksData, gqlTimeStampsData, gqlTimeFrameData]);

  const handleGetCows = () => {
    setMsgBox({ show: true, message: 'Fetching Cows', severity: 'info', autoHide: false });
    getSensors();
  }

  /**
   * Get respiration data for selected cow and time frame
   */
  const handleGetData = (liveOn?: boolean) => {
    if (cowId.length > 2) {
      if (manualSensorIdInputRef.current && manualSensorIdInputRef.current.value.length > 0) {
        setCowId(manualSensorIdInputRef.current?.value);
      }
      getTimeFrame();
      // if (liveOn && !liveActive) {
      //   setMsgBox({ show: true, message: 'Live', severity: 'warning', autoHide: false });
      //   setLiveActive(true);
      //   intervalId.current = setInterval(() => {
      //     const end = new Date().getTime();
      //     const start = new Date(end - 60000 * 2).getTime();
      //     getSensorData(cowId, start, end)
      //       .then((res: any) => {
      //         onChartData(res);
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //       })
      //   }, 30000);
      // }
    } else setMsgBox({ show: true, message: 'Select Cow ID first.', severity: 'warning', autoHide: true });
  }

  /**
  * Get time line for selected ID
  */
  const handleGetTimeLine = () => {
    if (cowId.length > 2) {
      getTimeStamps()
    } else setMsgBox({ show: true, message: 'Select Cow ID first.', severity: 'warning', autoHide: true });
  }

  const stopLive = () => {
    clearInterval(intervalId.current);
    setLiveActive(false);
  }

  function SensorSelector() {
    let sortedIds = [...cowIds].sort((a: any, b: any) => (a.lastPing < b.lastPing) ? 1 : ((b.lastPing < a.lastPing) ? -1 : 0))
    return (
      <Select
        labelId="selectCowId"
        id="selectCowId"
        value={cowId}
        renderValue={() => cowId}
        onChange={(event: any) => {
          setCowId(event.target.value);
          // onIDChange(event.target.value as string);
        }}
      >
        {
          sortedIds.map((e: any, i) => {
            const online = new Date().getTime() - e.lastPing > 60000 * 60 ? false : true;
            return <MenuItem key={i} value={e.cow_id} style={{ display: 'flex', flexDirection: 'row' }} >
              <div style={{
                marginRight: '4px', width: "12px", height: "12px", background: online ? "green" : "red", borderRadius: "25px"
              }}>
              </div>
              <div>
                {e.cow_id}
              </div>
            </MenuItem>
          })
        }
      </Select>
    )

  }

  return (
    <div>
      <div id="form" style={{ display: "flex", alignItems: "flex-end" }}>
        <FormControl style={{ width: 150 }}>
          <InputLabel id="selectCowIdLabel">Cow ID</InputLabel>
          <SensorSelector />
        </FormControl>
        <RestrictedAccess restrictedTo="admin" userGroup={userCtx?.groups}>
          <TextField id="manualSensorIdInput" label="Manual Cow ID override" inputRef={manualSensorIdInputRef} />
        </RestrictedAccess>
        <TimePicker label="Start Time" timeChanged={setStartTime} offset={600000}></TimePicker>
        <TimePicker label="End Time" timeChanged={setEndTime} offset={0}></TimePicker>
      </div>
      <div className="button-area" id="preselect">
        <Button variant="contained" onClick={() => {
          setEndTime(new Date().getTime());
          setStartTime(new Date().getTime() - 300000);
          handleGetData();
        }}>5</Button>
        <Button variant="contained" onClick={() => {
          setEndTime(new Date().getTime());
          setStartTime(new Date().getTime() - 900000);
          handleGetData();
        }}>15</Button>
        <Button variant="contained" onClick={() => {
          setEndTime(new Date().getTime());
          setStartTime(new Date().getTime() - 1800000);
          handleGetData();
        }}>30</Button>
        <Button variant="contained" onClick={() => {
          setEndTime(new Date().getTime());
          setStartTime(new Date().getTime() - 3600000);
          handleGetData();
        }}>60</Button>
        <Button variant="contained" onClick={() => {
          setEndTime(new Date().getTime());
          setStartTime(new Date().getTime() - 3600000 * 2);
          handleGetData();
        }}>2 Std.</Button>
        <Button variant="contained" onClick={() => {
          setEndTime(new Date().getTime());
          setStartTime(new Date().getTime() - 3600000 * 3);
          handleGetData();
        }}>3 Std.</Button>
      </div>
      <div className="button-area">
        {liveActive ?
          <Button onClick={stopLive} variant="contained" color={'secondary'}>Stop Live</Button>
          : <>
            {/* <Button onClick={() => handleGetData(true)} >Live Data</Button> */}
            <Button onClick={() => handleGetData()} >Get Data</Button>
            <Button onClick={handleGetTimeLine} >TimeLine</Button>
          </>
        }
      </div>
    </div>
  );
}
