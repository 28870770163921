import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import './styles/App.css';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import PrivateRoute from "./components/PrivateRoute";

// import SensorDetailsView from './views/SensorDetailsView';
import { GlobalMessageBoxProvider } from './common/context/GlobalMessageBoxContext'
import HomeView from './pages/HomeView';
import WelcomePage from './pages/WelcomePage'
// import ClimateView from './pages/ClimateView';
import SensorsView from './pages/SensorsView';
import DataPage from './pages/DataPage'
import AdministrationPage from './pages/AdministrationPage'
import PhonelinkSetupTwoToneIcon from '@material-ui/icons/PhonelinkSetupTwoTone';
import BugReportTwoTone from '@material-ui/icons/BugReportTwoTone';

// #########################################
// alles nur für den navigation drawer- START
import { NavLink } from 'react-router-dom'
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PollTwoToneIcon from '@material-ui/icons/PollTwoTone';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import { UserContext, IUserContext } from './common/context/UserContext';
import { gql, useLazyQuery } from '@apollo/client';
import IssueTrackerPage from './pages/IssueTrackerPage';
import { PrivateKeyInput } from 'crypto';
// import WbSunnyIcon from '@material-ui/icons/WbSunny';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);
// alles nur für den navigation drawer- ENDE
// #########################################

function App() {

  const { user, isAuthenticated } = useAuth0();

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { userCtx, setUserCtx } = useContext(UserContext) as IUserContext

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [getUser, { data }] = useLazyQuery(
    gql`query getUser($userId: String!) {
      getUser(id: $userId){
        groups,
        company
      },
    }`,
    { fetchPolicy: 'cache-and-network' }
  );

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    getUser({ variables: { userId: user.sub } })
  }, [isAuthenticated, user, setUserCtx, getUser])

  useEffect(() => {
    if (!data || !user) return;
    setUserCtx({
      id: user.sub,
      name: user.name,
      groups: data.getUser.groups,
      company: data.getUser.company,
    })
  }, [data, setUserCtx, user])

  useEffect(() => {
    if (!data) return;
  }, [data, user])

  return (
    <Router>
      <div className="App">
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar style={{ backgroundColor: 'green' }}>
              {isAuthenticated ?
                <>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                </>
                :
                <></>
              }
              <Typography variant="h6" noWrap>
                Gouna GmbH
              </Typography>
              <span style={{ flex: 1 }}></span>
              {userCtx ? userCtx.company : null}
              <span style={{ flex: 1 }}></span>
              <Link to={"/"}>
                {!user && (
                  <>
                    <LoginButton></LoginButton>
                  </>
                )}
                {user && (
                  <>
                    <div>
                      <LogoutButton name={userCtx?.name}></LogoutButton>
                    </div>
                  </>
                )}
              </Link>
              {/* <Button variant="contained" color="secondary">
                <Route path='/beta' component={() => {
                  window.location.href = 'https://dev.gouna.tech';
                  return null;
                }} />
              Try Test Version
              </Button> */}

            </Toolbar>
          </AppBar>
          {isAuthenticated ?
            <>
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
              >
                <div className={classes.toolbar}>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </div>
                <Divider />
                <List>
                  <ListItem
                    button
                    key="Home"
                    component={NavLink} to="/welcome"
                  >
                    <ListItemIcon>
                      <HomeTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItem>
                  <ListItem
                    button
                    key="Data"
                    component={NavLink} to="/data"
                  >
                    <ListItemIcon>
                      <PollTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Data View" />
                  </ListItem>
                  {/* <ListItem
                button
                key="Climate"
                component={NavLink}
                to="/climate"
              >
                <ListItemIcon>
                  <WbSunnyIcon />
                </ListItemIcon>
                <ListItemText primary="Climate" />
              </ListItem> */}
                  <ListItem
                    button
                    key="Sensors"
                    component={NavLink} to="/sensors"
                  >
                    <ListItemIcon>
                      <DashboardTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sensors" />
                  </ListItem>
                  {/* ADMIN AREA - put all non-public or testing stuff below */}
                  {userCtx?.groups?.includes('admin') ?
                    <>
                      <ListItem
                        button
                        key="Administration"
                        component={NavLink} to="/admin"
                      >
                        <ListItemIcon>
                          <PhonelinkSetupTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Administration" />
                      </ListItem>
                      <ListItem
                        button
                        key="IssueTracker"
                        component={NavLink} to="/issueTracker"
                      >
                        <ListItemIcon>
                          <BugReportTwoTone />
                        </ListItemIcon>
                        <ListItemText primary="IssueTracker" />
                      </ListItem>
                    </> : null
                  }
                </List>
              </Drawer>
            </>
            :
            <></>
          }
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <div style={{ width: "98%" }}>
              <Switch>

                <GlobalMessageBoxProvider>
                  <Route path="/" exact >
                    <HomeView></HomeView>
                  </Route>
                  <Route path="/welcome" exact component={WelcomePage} />
                  <PrivateRoute path="/sensors" exact component={SensorsView} />
                  <PrivateRoute path="/data/" exact component={DataPage} />
                  <PrivateRoute path="/data/:cowId" component={DataPage} />
                  <PrivateRoute path="/admin" component={AdministrationPage} />
                  <PrivateRoute path="/issueTracker" component={IssueTrackerPage} />
                </GlobalMessageBoxProvider>
              </Switch>
            </div>
          </main>
        </div>
      </div>
    </Router >
  );
}

export default App;