import { GroupSharp } from '@material-ui/icons';
import React, { useContext } from 'react'
import { UserContext, IUserContext } from '../common/context/UserContext';
import FormAddSensor from '../components/FormAddSensor'

// TODO access level auf acces group switchen nicht auf company abfragen

const AdministrationPage = (props: any) => {

  const { userCtx } = useContext(UserContext) as IUserContext;

  return (
    <div>
      {
        userCtx?.company === 'Gouna' ?
          < FormAddSensor />
          : null
      }
    </div>
  )
}

export default AdministrationPage;
