import React, { useState, useEffect, useRef, FunctionComponent } from 'react'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_boost from 'highcharts/modules/boost'
import HC_noData from 'highcharts/modules/no-data-to-display'
HC_boost(Highcharts)
HC_noData(Highcharts)

interface Props {
  data: Array<Array<Number>>; // Data for chart
  // data: Array<any>; // Data for chart
  title: string;  // Title of Chart
  dataId: string; // id must match data to request data from database. needed for onClick event on single bar of chart
  onData: Function;
}

export const TimeLineChart: FunctionComponent<Props> = ({ data, dataId, title, onData }) => {


  const chartConfig: Highcharts.Options = {
    title: {
      text: 'Time Line'
    },
    caption: {
      text: '* Use shift to scroll view',
      align: 'left',
    },
    tooltip: {
      enabled: false
    },
    boost: {
      seriesThreshold: 100,
      enabled: true,
      allowForce: true
    },
    plotOptions: {
      bar: {
        animation: false,
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          }
        }
      },
      series: {
        events: {
          click: function (e: any) {
            // handleChartClick(e.point.x);
          }
        },
        marker: {
          enabled: false
        },
        animation: false,
        allowPointSelect: false
      }
    },
    legend: {
      enabled: false,
    },
    chart: {
      height: 150,
      zoomType: 'x',
      resetZoomButton: {
        position: {
          x: -40,
          y: -40
        }
      },
      panKey: 'shift',
      panning: {
        enabled: true,
        type: 'x'
      },
    },
    lang: {
      noData: 'Shows available data of selected cow'
    },
    xAxis: {
      type: 'datetime',
      labels: {
        //   // ###           Formate
        //   // ###  millisecond: '%H:%M:%S.%L',
        //   // ###       second: '%H:%M:%S',
        //   // ###       minute: '%H:%M',
        //   // ###         hour: '%H:%M',
        //   // ###          day: '%e. %b',
        //   // ###         week: '%e. %b',
        //   // ###        month: '%b \'%y',
        //   // ###         year: '%Y'
        formatter() {
          return Highcharts.dateFormat('%e.%b-%H:%M', this.value)
          // return Highcharts.dateFormat('%H:%M:%S.%L', this.value);
        }
        // rotation: -10,
      }
    },
    series: [
    ],
    yAxis: [
      {
        zoomEnabled: true,
        visible: false
      },
    ],
  }

  // const { getSensorData } = ApiCalls();
  // const { setMsgBox } = useMsgBox();
  // const { isAuthenticated, isLoading } = useAuth0()
  // @ts-ignore
  const [chartData, setChartData] = useState<Highcharts.Options>(chartConfig);
  const dataIdBuf = useRef("");

  /**
   * Triggered on click of bar in chart to request data from selected time stamp
   * @param {number} time 
   */
  // const handleChartClick = (x: number) => {
  //   console.log(isAuthenticated + "\n" + isLoading)
  //   const time = x + new Date().getTimezoneOffset() * 60 * 1000;
  //   console.log(dataIdBuf.current + "\n" + (time - 600000) + "\n" + (time + 600000));
  //   getSensorData(dataIdBuf.current, (time - 600000), (time + 600000)) // +- 10 minutes
  //     .then((res: any) => {
  //       setMsgBox({ show: false });
  //       onData(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setMsgBox({ show: true, message: 'Failed Fetching Data', severity: 'error', autoHide: true });
  //     })
  // }
  /**
   * Triggered on update of data prop to update chart
   */
  useEffect(() => {
    dataIdBuf.current = dataId;
    const offset = - new Date().getTimezoneOffset() * 60 * 1000;
    const newData = data.map((e: any) => [e + offset, 1])
    setChartData(prevState => {
      return {
        ...prevState,
        series: [{
          type: "column",
          data: newData.sort((p1: any, p2: any) => p1[0] - p2[0]),
        }],
        title: {
          text: title
        }
      }
    })
  }, [data])

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartData}
      />
    </div>
  )
}

export default TimeLineChart;