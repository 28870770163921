import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';


export default function HomeView() {

  const { isAuthenticated } = useAuth0();
  let history = useHistory();

  useEffect(() => {
    if (isAuthenticated) history.push('/welcome');
  })

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <h1>Welcome to the Gouna GmbH Dashboard</h1>
      <h2>You will be able to see all your Sensors, Data and Configuration once you are logged in.</h2>
    </div>
  )
}
