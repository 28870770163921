import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { gql, useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';

interface Props {
  elements: null | undefined | [{}];
}
const StationsStatus: React.FC<Props> = ({ elements }) => {

  const [openTunnel, { data }] = useMutation(gql`
  mutation openTunnel($nick: String!) {
    tunnel(
        station: $nick,
      ){
        command,
        nick
      },
    },
    `,
  );

  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {elements ? elements.map((e: any, i: number) => {
        const online = new Date().getTime() - e.date > 120001 ? false : true;
        return (
          <Card key={i} style={{ margin: "1em", width: "24em" }}>
            <CardHeader
              title={<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                {e.nick}
                <div style={{
                  width: "14px", height: "14px", background: online ? "green" : "red", borderRadius: "25px"
                }}>
                </div>
              </div>}
              subheader={e.date ? `Seen: ${new Date(e.date).toLocaleDateString()} ${new Date(e.date).toLocaleTimeString()}` : `Seen: never`}
            />
            <CardActions>
              <Button onClick={() => {
                openTunnel({
                  variables: {
                    nick: e.nick
                  }
                })
              }}>Tunnel</Button>
              <Typography variant="caption">{data && data.tunnel.nick === e.nick ? "Tunnel should be opened" : null}</Typography>
            </CardActions>
          </Card>)
      }
      )
        : null}
    </div>
  )
}

export default StationsStatus;