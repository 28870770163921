import React, { createContext, FC, useState } from 'react';

interface IUser {
  id?: string;
  name?: string;
  groups?: Array<string>
  company?: string;
}

export interface IUserContext {
  userCtx: IUser | null;
  setUserCtx: (data: IUser) => void;
}

const UserContext = createContext<IUserContext | null>(null);

const UserContextProvider: FC = ({ children }) => {
  const [userCtx, setUserCtx] = useState<IUser | null>(null);


  return (
    <UserContext.Provider value={{ userCtx, setUserCtx }} >
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserContextProvider };