import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import TableSort from '../components/TableSort';
import SensorsModel from '../common/models/SensorsModel'
import SensorRow from '../common/models/SensorRow'
import { ObjectSortByKey } from '../common/utils/ObjectSort'

// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import RefreshIcon from "@material-ui/icons/RefreshOutlined";
import FilterIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useMsgBox } from "../common/context/GlobalMessageBoxContext";
import Typography from "@material-ui/core/Typography";
import RestrictedAccess from "../components/RestrictedAccess";
import { IUserContext, UserContext } from "../common/context/UserContext";


interface Props {
  data?: Array<SensorRow> | null;
}

// TODO styles aufräumen 
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40
  },
  input: {
    width: 130,
    height: 40
  }
}));

// @ts-ignore
const CustomTableCell = ({ row, name, onChange, allowChange = true }) => {
  const classes = useStyles();
  const { editable } = row;
  return (
    <TableCell align="left" className={classes.tableCell}>
      {editable && allowChange ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name] instanceof Array ? row[name].map((e: any, i: number) =>
          <div key={i}>
            {`${e},`}
          </div>)
          : row[name]
      )}
    </TableCell>
  );
};

// @ts-ignore
const ChipCell = ({ row, name, onChange, allowChange = true }) => {
  const classes = useStyles();
  const { editable } = row;
  return (
    <TableCell align="left" className={classes.tableCell}>
      {editable && allowChange ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name] instanceof Array ? row[name].map((e: any, i: number) =>
          <>
            <Chip key={i} style={{ marginRight: "8px" }} color="primary" label={e} />
          </>
        ) : row[name]
      )}
    </TableCell>
  );
};


// @ts-ignore
const OnlineIndicator = ({ row, name }) => {
  const [hover, setHover] = useState(false)
  const classes = useStyles();
  const time = row[name];
  const online = new Date().getTime() - time > 60000 * 60 ? false : true;
  const formatedTime = new Intl.DateTimeFormat('de-DE', { day: "numeric", month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }).format(time);
  return (
    <TableCell onMouseEnter={() => {
      setHover(!hover)
    }} onMouseLeave={() => {
      setHover(!hover)
    }}
      align="left" className={classes.tableCell} >
      {
        hover ?
          <Typography variant="caption">
            {formatedTime}
          </Typography>
          :
          <div style={{
            width: "14px", height: "14px", background: online ? "green" : "red", borderRadius: "25px",
          }}>
          </div>
      }
    </TableCell >
  );
};

function SensorsTable({ data }: Props) {

  const { setMsgBox } = useMsgBox();
  const { userCtx, setUserCtx } = useContext(UserContext) as IUserContext;


  const classes = useStyles();
  const [rows, setRows] = useState<Array<SensorRow> | null>([]);
  const [previous, setPrevious] = useState({});
  const [toggleFilter, setToggleFilter] = useState(false)
  const [filter, setFilter] = useState("")

  const [getSensors, { loading: gqlSensorLoading, data: gqlSensorsData, error: gqlSensorError }] = useLazyQuery<SensorsModel>(
    gql`query getSensors {
      getSensors{
        sqlId,
        cow_id,
        groups,
        sensor_id,
        lastPing,
        tags
      },
    },
    `,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  );

  useEffect(() => {
    getSensors();
  }, [])

  // gql loading
  useEffect(() => {
    if (gqlSensorLoading) setMsgBox({ show: true, message: 'Loading', severity: 'info', autoHide: false });
    else setMsgBox({ show: false });
  }, [gqlSensorLoading,]);

  // gql error
  useEffect(() => {
    if (gqlSensorError) setMsgBox({ show: true, message: `Error`, severity: 'warning', autoHide: true });
    else setMsgBox({ show: false });
  }, [gqlSensorError]);

  // effect on data
  useEffect(() => {
    if (gqlSensorsData) {
      const tempRows: Array<SensorRow> = [];
      let index = -1;
      gqlSensorsData.getSensors.forEach(
        (e: any) => {
          index += 1;
          tempRows.push({ sqlId: e.sqlId, id: index, cowId: e.cow_id, sensorId: e.sensor_id?.toString(), groups: e.groups, lastPing: e.lastPing, tags: e.tags, editable: false, visible: true });
        }
      )
      tempRows.sort(ObjectSortByKey('lastPing', "desc"))
      setRows([...tempRows]);
    }
  }, [gqlSensorsData]);


  const [updateSensor, { loading: gqlLoading, error: gqlError }] = useMutation(gql`
  mutation updateSensor($sqlId: Float, $cowId: String, $sensorId: Int, $groups: [String!], $tags: [String!]) {
    updateSensor(
        sqlId: $sqlId
        cowId: $cowId,
        sensorId: $sensorId,
        groups: $groups
        tags: $tags
      ){
        cow_id
      },
    },
    `,
    {
      errorPolicy: 'all'
    }
  );

  // gql loading
  useEffect(() => {
    if (gqlLoading) setMsgBox({ show: true, message: 'Loading', severity: 'info', autoHide: false });
    else setMsgBox({ show: false });
  }, [gqlLoading,]);

  // gql error
  useEffect(() => {
    if (gqlError) setMsgBox({ show: true, message: `Error`, severity: 'warning', autoHide: true });
    else setMsgBox({ show: false });
  }, [gqlError]);

  const onToggleEditMode = (id: any) => {
    if (!rows) return;
    setRows(state => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, editable: !row.editable };
        }
        return row;
      });
    });
  };

  const onChange = (e: any, row: any) => {
    if (!rows) return;
    //@ts-ignore
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const name = e.target.name;
    const valuePlain = e.target.value.split(",");
    let value: string | [string];
    if (valuePlain.length > 1) value = valuePlain.map((str: string) => str.trim());
    else value = valuePlain[0];
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (id: number) => {
    if (!rows) return;
    const newRows = rows.map(row => {
      if (row.id === id) {
        //@ts-ignore
        return previous[id] ? { ...previous[id], editable: false } : { ...row, editable: false };
      }
      return row;
    });
    setRows(newRows);
    setPrevious(state => {
      //@ts-ignore
      delete state[id];
      return state;
    });
  };

  const onRefresh = () => {
    getSensors();
  }

  const onFilter = (arg: string) => {
    setFilter(arg)
    let result: Array<any> = [];
    rows!.forEach((row) => {
      let visible = false;
      for (let cell in row) {
        //@ts-ignore
        if (typeof (row[cell]) === "string" && row[cell].toString().toUpperCase().indexOf(arg.toUpperCase()) !== -1) {
          visible = true;
          break;
        }
      }
      return result.push({ ...row, visible });
    });
    setRows(result);
  }

  return (
    <Paper className={classes.root}>
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <IconButton
          style={{ marginLeft: "0.6em", marginTop: "0.6em" }}
          aria-label="refresh"
          onClick={() => onRefresh()}
        >
          <RefreshIcon />
        </IconButton>
        {toggleFilter ?
          < >
            <span style={{ width: "100%" }} />
            <Input
              style={{ marginRight: "0.6em", marginTop: "0.6em" }}
              value={filter}
              name={"filter"}
              onChange={(e) => {
                onFilter(e.target.value);
              }}
            />
            <IconButton
              style={{ marginRight: "0.6em", marginTop: "0.6em" }}
              aria-label="filter"
              onClick={() => {
                let temp = rows?.map(row => {
                  return { ...row, visible: true }
                })
                setRows(temp!);
                setFilter('');
                setToggleFilter(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </> :
          <IconButton
            style={{ marginRight: "0.6em", marginTop: "0.6em" }}
            aria-label="revert"
            onClick={() => setToggleFilter(!toggleFilter)}
          >
            <FilterIcon />
          </IconButton>
        }
      </div>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell align="left" />
            <TableSort title="Online" tableData={rows} cell="cowId" onSortResult={(result: any) => setRows([...result])} />
            <TableSort title="Sensor Id" tableData={rows} cell="sensorId" onSortResult={(result: any) => setRows([...result])} />
            <TableSort title="Cow Id" tableData={rows} cell="cowId" onSortResult={(result: any) => setRows([...result])} />
            <TableCell align="left"> Tags </TableCell>
            <TableCell align="left"> Groups </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            row.visible ?
              <TableRow key={row.id}>
                <TableCell className={classes.selectTableCell}>
                  <RestrictedAccess restrictedTo="exporter" userGroup={userCtx?.groups}>
                    {row.editable ? (
                      <>

                        <IconButton
                          aria-label="done"
                          onClick={() => {
                            updateSensor({
                              variables: {
                                sqlId: row.sqlId,
                                cowId: row.cowId,
                                sensorId: parseInt(row.sensorId),
                                groups: row.groups,
                                tags: row.tags
                              }
                            });
                            onToggleEditMode(row.id)
                          }}
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton
                          aria-label="revert"
                          onClick={() => onRevert(row.id)}
                        >
                          <RevertIcon />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        onClick={() => onToggleEditMode(row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </RestrictedAccess>
                </TableCell>
                <OnlineIndicator {...{ row, name: "lastPing" }} />
                <CustomTableCell {...{ row, name: "sensorId", onChange, allowChange: false }} />
                <CustomTableCell {...{ row, name: "cowId", onChange }} />
                <ChipCell {...{ row, name: "tags", onChange }} />
                <ChipCell {...{ row, name: "groups", onChange }} />
              </TableRow>
              : null
          ))}
        </TableBody>
      </Table>
    </Paper >
  );
}

export default SensorsTable;