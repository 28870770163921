import React, { createContext, FC, useState } from 'react';

interface IData {
  id?: string;
  name?: string;
  groups?: Array<string>
  company?: string;
}

export interface IDataContext {
  userCtx: IData | null;
  setUserCtx: (data: IData) => void;
}

const DataContext = createContext<IDataContext | null>(null);

const GlobalDataContextProvider: FC = ({ children }) => {
  const [userCtx, setUserCtx] = useState<IData | null>(null);


  return (
    <DataContext.Provider value={{ userCtx, setUserCtx }} >
      {children}
    </DataContext.Provider>
  );
}

export { DataContext, GlobalDataContextProvider };