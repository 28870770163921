import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

const AuthorizedApolloProvider = ({ children }: { children: any }) => {
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL + 'graphql',
    // credentials: 'same-origin'
  });

  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
  });

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}graphql`,
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

export default AuthorizedApolloProvider;