import React, { createContext, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


interface IGlobalMessageBox {
  show: boolean;
  severity?: "info" | "success" | "warning" | "error" | undefined;
  message?: string;
  autoHide?: boolean;
  spinner?: boolean;
}

interface IContext {
  msgBox: IGlobalMessageBox;
  setMsgBox: (value: IGlobalMessageBox) => void;
}

const box: IGlobalMessageBox = { show: false, severity: 'info', message: 'Working...', autoHide: true, spinner: false };

export const GlobalMessageContext = createContext<IContext>({
  msgBox: box,
  setMsgBox: (value: IGlobalMessageBox) => { }
});

export const GlobalMessageBoxProvider = (props: any) => {
  const [msgBox, setMsgBox] = useState<IGlobalMessageBox>(box);

  const handleClose = () => {
    setMsgBox({ show: false, severity: undefined });
  }

  return (
    <GlobalMessageContext.Provider value={{ msgBox, setMsgBox }}>
      {
        msgBox.show ?
          <Snackbar
            style={{ marginTop: 100 }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={msgBox.show}
            message="bla"
            autoHideDuration={msgBox.autoHide ? 4000 : null}
            onClose={handleClose}
          >
            <Alert severity={msgBox.severity}
              action={
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {msgBox.spinner ?
                  <CircularProgress color="primary" size={18} /> :
                  null
                }
                <span style={{ width: "1em" }}></span>
                <Typography variant="body1">
                  {msgBox.message}
                </Typography>
              </div>
            </Alert>
          </Snackbar>
          :
          <></>
      }
      { props.children}
    </GlobalMessageContext.Provider >
  )
}

export const useMsgBox = () => React.useContext(GlobalMessageContext);
