import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@material-ui/core/Button';
import MeetingRoomTwoToneIcon from '@material-ui/icons/MeetingRoomTwoTone';

interface IProps {
  name?: string;
}

const LogoutButton: React.FC<IProps> = ({ name }) => {
  const { logout } = useAuth0();

  return <Button
    startIcon={<MeetingRoomTwoToneIcon />}
    style={{ width: 150, color: "white" }}
    variant="text"
    onClick={() => logout({ returnTo: `${process.env.REACT_APP_URL}` })}
  >
    {name ?
      <>{name}</> :
      <>Logout</>
    }
  </Button>;
};

export default LogoutButton;