import React, { useState } from "react";
import SensorRow from "../common/models/SensorRow"
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from "@material-ui/core/TableCell/TableCell";
import { ObjectSortByKey } from "../common/utils/ObjectSort";

interface Props {
  tableData: Array<SensorRow> | null;
  cell: string;
  onSortResult: any;
  title: string;
}

function TableSort({ tableData, cell, onSortResult, title }: Props) {

  // const sortingOrder = useState<string | number>(0);
  const [sortingOrder, setSortingOrder] = useState<string>('');

  if (!tableData) return <></>;

  if (sortingOrder === "desc") {
    return (
      <TableCell align="left">
        {title}
        <TableSortLabel active direction={"asc"} onClick={() => {
          if (!tableData) return;
          const temp = tableData.sort(ObjectSortByKey(cell));
          onSortResult(temp);
          setSortingOrder("asc");
        }} />
      </TableCell>
    )
  }

  if (sortingOrder === "asc") {
    return (
      <TableCell align="left">
        {title}
        <TableSortLabel active direction={"desc"} onClick={() => {
          if (!tableData) return;
          const temp = tableData.sort(ObjectSortByKey(cell, "desc"));
          onSortResult(temp);
          setSortingOrder("desc");
        }} />
      </TableCell>
    )
  }

  return (
    <>
      <TableCell align="left">
        {title}
        <TableSortLabel active direction={"asc"} onClick={() => {
          if (!tableData) return;
          const temp = tableData.sort(ObjectSortByKey(cell));
          onSortResult(temp);
          setSortingOrder("asc");
        }} />
        <TableSortLabel active direction={"desc"} onClick={() => {
          if (!tableData) return;
          const temp = tableData.sort(ObjectSortByKey(cell, "desc"));
          onSortResult(temp);
          setSortingOrder("desc");
        }} />
      </TableCell>
    </>
  )
}

export default TableSort;